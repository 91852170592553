'use client';

import { FC, PropsWithChildren } from 'react';
import { WorldMap } from './assets/WorldMap';

import { ContextualPopupSection } from './ContextualPopupSection';

export interface ContextualPopupWorldMapProps {
  lat: number;
  long: number;
}

export const ContextualPopupWorldMap: FC<
  PropsWithChildren<ContextualPopupWorldMapProps>
> = ({ lat, long }) => {
  return (
    <ContextualPopupSection paddingBottom={8}>
      <WorldMap lat={lat} long={long} />
    </ContextualPopupSection>
  );
};
