'use client';

import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Box, BoxProps } from '../Box';

export interface ContextualPopupSectionProps extends BoxProps {}

export const ContextualPopupSection: FC<
  PropsWithChildren<ContextualPopupSectionProps>
> = ({ children, ...props }) => (
  <Root padding={16} {...props}>
    {children}
  </Root>
);

const Root = styled(Box)`
  overflow: auto;
`;
