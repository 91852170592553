'use client';

import { rgba } from 'polished';
import {
  FC,
  PropsWithChildren,
  ReactNode,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
  PropsWithoutRef,
} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Reveal } from '../Reveal';
import { Typography } from '../Typography';

export interface ContextualPopupProps<T = any> {
  behaviour?: 'manual' | 'hover' | 'click';
  handle?: ReactNode;
  handleComponent?: FC<PropsWithChildren<T>>;
  handleProps?: PropsWithoutRef<T>;
  disabled?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const ContextualPopup: FC<PropsWithChildren<ContextualPopupProps>> = ({
  behaviour,
  handle,
  handleComponent: HandleComponent,
  handleProps,
  children,
  disabled = false,
  open: manualOpen = false,
  onOpenChange,
  ...props
}) => {
  const portalElement =
    typeof document !== 'undefined' ? document.getElementById('portal') : null;
  const [open, setOpen] = useState(false);
  const handleRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [secondRender, setSecondRender] = useState(false);

  /**
   * Fix SSR issue by rendering modals only on the second render
   */
  useEffect(() => {
    setSecondRender(true);
  }, []);

  const refresh = () => {
    const handle = handleRef.current;
    const popup = popupRef.current;

    if (!handle || !popup) return;

    const handleRect = handle.getClientRects()[0];
    const popupRect = popup.getClientRects()[0];

    const { scrollTop, clientWidth } = document.body;

    const top = handleRect.top + handleRect.height + scrollTop + 8;
    const left = handleRect.left + handleRect.width / 2 - popupRect.width / 2;

    const minLeft = 16;
    const maxLeft = clientWidth - popupRect.width - 16;

    popup.style.top = `${top}px`;
    popup.style.left = `${
      left < minLeft ? minLeft : left > maxLeft ? maxLeft : left
    }px`;
  };

  const setPopupOpen = (popupOpen: boolean) => {
    setOpen(popupOpen);
    onOpenChange?.(popupOpen);

    if (popupOpen) {
      refresh();

      addEventListener('resize', refresh);
    } else {
      removeEventListener('resize', refresh);
    }
  };

  const onHandleClick: HTMLAttributes<HTMLDivElement>['onClick'] = (event) => {
    event.stopPropagation();

    setPopupOpen(!open);
  };
  const onPopupClick: HTMLAttributes<HTMLDivElement>['onClick'] = (event) => {
    event.stopPropagation();
  };

  const onHandleMouseOver = () => {
    setPopupOpen(true);
  };
  const onHandleMouseOut = () => {
    setPopupOpen(false);
  };

  const onBackdropClick = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      refresh();
    }, 1000);
  }, []);

  return (
    <>
      <Handle
        ref={handleRef}
        onClick={behaviour === 'click' ? onHandleClick : undefined}
        onMouseOver={behaviour === 'hover' ? onHandleMouseOver : undefined}
        onMouseOut={behaviour === 'hover' ? onHandleMouseOut : undefined}
        {...handleProps}
      >
        {HandleComponent ? <HandleComponent /> : handle}
      </Handle>

      {portalElement &&
        secondRender &&
        ReactDOM.createPortal(
          <>
            {open && <PopupBackdrop onClick={onBackdropClick} />}

            <PopupRoot
              ref={popupRef}
              onClick={onPopupClick}
              open={behaviour === 'manual' ? manualOpen : open}
              {...props}
            >
              <PopupBackground open={open} size={480} spread={0} />
              <PopupBorder />

              {children}
            </PopupRoot>
          </>,
          portalElement
        )}
    </>
  );
};

() => <ContextualPopup handleComponent={Typography.Paragraph} />;

const Handle = styled.span`
  display: inherit;
  position: relative;
  cursor: pointer;
  z-index: 10;
`;

const PopupBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  z-index: 9998;
`;

const PopupRoot = styled.div<{ open?: boolean }>`
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 9999;
  border: 0px solid
    ${({ theme }) => rgba(theme.foreground, theme.dark ? 0.1 : 0.1)};
  box-sizing: border-box;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.025), 0px 16px 64px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  max-width: 320px;
  min-width: 280px;
  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  transform: translateY(${({ open }) => (open ? 0 : 8)}px);
  transition: transform 0.1s, opacity 0.1s;
  padding: 1px;

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(32px);
  }
`;

const PopupBackground = styled(Reveal)<{ open?: boolean }>`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  pointer-events: none;
  opacity: ${({ open }) => (open ? 1 : 0)};

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const PopupBorder = styled(Reveal)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 1px solid
    ${({ theme }) => rgba(theme.foreground, theme.dark ? 0.2 : 0.1)};
  border-radius: 8px;
  pointer-events: none;
`;
