'use client';

import { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';
import { TypographyProps, typographyHelper } from './utils/typographyHelper';
import {
  SpacingTypographyProps,
  spacingTypographyHelper,
} from './utils/spacingTypographyHelper';

export interface BaseProps extends TypographyProps, SpacingTypographyProps {
  children?: ReactNode;
  className?: string;
}

export const Base = forwardRef<HTMLElement, BaseProps>((props, ref) => {
  const { children } = props;
  return (
    <Root ref={ref} {...props}>
      {children}
    </Root>
  );
});

const RootComponent = forwardRef<HTMLElement, BaseProps>((props, ref) => {
  const { className, children } = props;
  return (
    <span ref={ref} className={className}>
      {children}
    </span>
  );
});

const RootWithTheme = styled(RootComponent)(({ theme }) => ({
  color: theme.foreground,
}));

const Root = styled(RootWithTheme)`
  ${typographyHelper};
  ${spacingTypographyHelper};
`;
