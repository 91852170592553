'use client';

import { FC, PropsWithChildren } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Reveal, RevealProps } from '../Reveal';
import { marginHelper, MarginProps } from '../../utils/marginHelper';
import { displayHelper, DisplayProps } from '../../utils/displayHelper';

export interface DividerHorizontalProps
  extends DisplayProps,
    MarginProps,
    RevealProps {
  className?: string;
  disabledReveal?: boolean;
}

export const DividerHorizontal: FC<
  PropsWithChildren<DividerHorizontalProps>
> = (props) => {
  const { className, disabledReveal } = props;
  const revealProps: RevealProps = {};

  if (disabledReveal) {
    revealProps.activeOpacity = 1;
    revealProps.inactiveOpacity = 1;
  }

  return <Root className={className} {...revealProps} {...props} />;
};

const Root = styled(Reveal)<DividerHorizontalProps>`
  width: 100%;
  height: ${({ theme }) => theme.divider.width}px;
  background: ${({ theme }) => rgba(theme.foreground, theme.divider.opacity)};
  transition: ${({ theme }) => theme.transition};
  ${displayHelper}
  ${marginHelper}
`;
